







































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import * as _ from 'lodash';
import CAR_FUEL_TYPE from '@/modules/cars/constants/car-fuel-type.constant';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import { DatePicker } from 'element-ui';
import NotificationsService, { NotificationsServiceS } from '../notifications.service';
import NotificationsStore from '../store/notifications-store';
import { defaultTrendVal } from '../../settings/config/alerts-notification.config';
import { CarAlertType, CarAlertTypeList } from '../../settings/enums/alerts.enum';

@Component({
    components: {
        CustomMultiSelect,
        CustomSelect,
        StyledSearchbar,
        DatePicker,
    },
})
export default class NotificationFilters extends Vue {
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(NotificationsServiceS) notificationsService!: NotificationsService;

    readonly storeState: NotificationsStore = this.storeFacade.getState('NotificationsStore');

    countryQuery: string | null = null;
    locationQuery: string | null = null;
    firstLokLoad: boolean = false;

    mounted() {
        const { dataSources } = this.carsSharedService.filters;
        this.storeState.isUserHaveBrand = Boolean(dataSources?.length && dataSources?.includes('Brand'));
        if (this.storeState.isUserHaveBrand) {
            this.storeState.fuelType = this.fuelTypesList;
            this.storeState.carCategory = this.getCarCategoryList;
            this.storeState.location = this.getLocationList;
            this.storeState.POS = this.getPosList;
            this.storeState.competitors = this.competitorsList;
            this.storeState.LOK = this.getLokList;
        }
        this.storeState.alertType = this.getAlertTypes;
        this.storeState.filterReady = true;
    }

    @Watch('storeState.POS', { deep: true })
    @Watch('storeState.location', { deep: true })
    updateLok() {
        this.LOK = this.getLokList;
        this.competitors = this.competitorsList;
    }

    get seenType() {
        return this.storeState.seen;
    }

    set seenType(seen: number) {
        this.storeState.seen = seen;
    }

    get seenTypeList() {
        return [
            {
                name: 'Any',
                value: 2,
            },
            {
                name: 'Seen',
                value: 1,
            },
            {
                name: 'Unseen',
                value: 0,
            },
        ];
    }

    get fuelType() {
        return this.storeState.fuelType;
    }

    set fuelType(fuelType) {
        this.storeState.fuelType = fuelType;
    }

    get carCategory() {
        return this.storeState.carCategory;
    }

    set carCategory(carCategory) {
        this.storeState.carCategory = carCategory;
    }

    get LOK() {
        return this.storeState.LOK;
    }

    set LOK(lok) {
        this.storeState.LOK = lok;
    }

    get POS() {
        return this.storeState.POS;
    }

    set POS(pos) {
        this.storeState.POS = pos;
    }

    get competitors() {
        return this.storeState.competitors;
    }

    set competitors(competitors) {
        this.storeState.competitors = competitors;
    }

    get dataSource() {
        return this.storeState.dataSource;
    }

    set dataSource(dataSource) {
        this.storeState.dataSource = dataSource;
    }

    get fuelTypesList() {
        const fuleTypes = CAR_FUEL_TYPE;
        if (!fuleTypes) {
            return [];
        }
        const data = fuleTypes.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get getCarCategoryList() {
        const categories = this.carsFiltersService.allCarClasses;
        if (!categories) {
            return [];
        }
        const data = categories.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get getLokList() {
        let allLors: string[] = [];
        this.storeState.location.forEach(location => {
            this.POS.forEach(pos => {
                const currentLor = this.carsFiltersService.getAvailableLors(
                    location.value,
                    pos.value,
                    this.storeState.dataSource,
                    '',
                    false,
                );
                allLors = [...allLors, ...currentLor];
            });
        });
        allLors = _.uniq(allLors);
        if (allLors?.length === 0) {
            return [];
        }
        const lors = allLors.map(Number).sort((a, b) => a - b).map(value => ({
            name: String(value),
            value: String(value),
        }));

        return lors;
    }

    get getPosList() {
        const { pos } = this.carsSharedService.filters;
        const { allowedLocationPerPos } = this.carsFiltersService;

        if (!allowedLocationPerPos || !this.currentLocations.length) {
            return [];
        }

        const selectedLocations = this.currentLocations.map(location => location.value);
        const posList = Array.from(allowedLocationPerPos).filter(([key, value]) => selectedLocations.includes(key)).map(([key, value]) => value);
        const availablePos = _.union(...posList);
        if (Array.from(availablePos).length === 0 && pos && this.currentCountry === COUNTRIES_ANY) {
            return pos.map(value => ({
                name: String(value),
                value: String(value),
            }));
        }
        return Array.from(availablePos).map(value => ({
            name: String(value),
            value: String(value),
        }));
    }

    get competitorsList() {
        if (!this.dataSource && !this.POS?.length) {
            return [];
        }

        let competitorsFilter: string[] = [];
        this.POS.forEach(pos => {
            const competitorsData = this.carsFiltersService.getCompetitorsFilterByDataSource(this.dataSource, pos.value, this.currentCountry);
            competitorsFilter = competitorsFilter.length ? _.union(competitorsFilter, competitorsData) : competitorsData;
        });

        if (!competitorsFilter.length) {
            return [];
        }
        const data = competitorsFilter.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            return 1;
        });
    }

    get getAlertTypes() {
        if (!this.storeState.isUserHaveBrand) {
            return [{
                name: 'Sipp Code',
                value: CarAlertType.SIPP_CODE,
            }, {
                name: 'Car Category',
                value: CarAlertType.CAR_CATEGORY,
            }];
        }
        return [{
            name: 'Sipp Code',
            value: CarAlertType.SIPP_CODE,
        }, {
            name: 'Car Category',
            value: CarAlertType.CAR_CATEGORY,
        }, {
            name: CarAlertTypeList.RATE_DIFF,
            value: CarAlertType.RATE_DIFF,
        }, {
            name: CarAlertTypeList.BRAND_DIFF,
            value: CarAlertType.BRAND_DIFF,
        }, {
            name: CarAlertTypeList.COMPSET_DIFF,
            value: CarAlertType.COMPSET_DIFF,
        }, {
            name: CarAlertTypeList.COMPETITORS_DIFF,
            value: CarAlertType.COMPETITORS_DIFF,
        }, {
            name: CarAlertTypeList.TREND_DIFF,
            value: CarAlertType.TREND_DIFF,
        }];
    }

    get alertTypes() {
        return this.storeState.alertType;
    }

    set alertTypes(types) {
        this.storeState.alertType = types;
    }

    get trendValItems() {
        return defaultTrendVal;
    }

    get trendVal() {
        return this.storeState.trend;
    }

    set trendVal(val) {
        this.storeState.trend = val;
    }

    get countryItems() {
        let clustersKeys = this.carsFiltersService.getClustersKeys();
        if (!clustersKeys) {
            return [];
        }

        if (this.countryQuery) {
            const searchPattern = new RegExp(this.countryQuery.toLowerCase());
            clustersKeys = clustersKeys.filter(country => searchPattern.test(country.value.toLowerCase()));
        }

        if (!clustersKeys.length) {
            return ([{
                name: 'No results',
                value: 'No results',
                disabled: true,
            }]);
        }

        return clustersKeys;
    }

    get currentCountry() {
        return this.storeState.country;
    }

    set currentCountry(value) {
        this.storeState.country = value;
        this.storeState.location = this.getLocationList;
        this.storeState.POS = this.getPosList;
    }

    get getLocationList() {
        const filteredLocations = this.carsFiltersService.filterPickUpCitiesByCountry(this.currentCountry);

        if (!filteredLocations) {
            return [];
        }

        let data = filteredLocations.map(value => ({
            name: String(value.locationName),
            value: String(value.locationId),
        }));

        if (this.locationQuery) {
            const searchPattern = new RegExp(this.locationQuery.toLowerCase());
            data = data.filter(location => searchPattern.test(location.name.toLowerCase()));
        }

        return data;
    }

    set currentLocations(values: Record<string, any>[]) {
        this.storeState.location = values;
        this.storeState.POS = this.getPosList;
    }

    get currentLocations() {
        return this.storeState.location;
    }

    get getDataSourceList() {
        const dataSource = ['Brand'];

        const data = dataSource.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    handleCountryListChange(query: string) {
        this.countryQuery = query;
    }

    handleLocationListChange(query: string) {
        this.locationQuery = query;
    }
}
